
.welcome{
    background-color: #ffd300;
    height: 100vh;
  

    h1{
        font-size: 21px;
        padding-top: 10px;
        padding-left: 10px;
    }

    p{
        text-align: center;
        font-weight: 500;
        position: relative;
        top: 10px;
      
    }

    .expBox{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .exp{
            margin-bottom: 50px;
            font-size: 1.5em;
        }
        .exp::first-letter{
            text-transform: uppercase;
        }
        .btns{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    
        button{
            background-color: #010101;
            color: #fff;
            width: 150px;
            text-align: center;
            border-radius: 5px;
            padding: 10px;
            cursor: pointer;

            &:hover{
                background-color: #fff;
                color: #010101;
            }
        }

    }


    form{

    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #010101;
    width: fit-content;
    padding: 40px;
    border-radius: 10px;
    margin: 0 auto;
    
    

    label{
        color: #fff;
    }

    input{
       width: 250px;
       padding: 8px;
    }
    
    button{
        width: 250px;
        padding: 8px;
        cursor: pointer;
        margin-top: 5px;
        background-color: #ffd300;
        color: #fff;
     &:hover{
        background-color: #fff;
        color: #010101;
     }
    }
    }

  
}

.auth-header{
    background-color: #010101;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: sticky;
    top: 0;
     z-index: 100000;
     border-bottom: "1px solid #ffd300";

     .header_options{
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        @media only screen and (max-width : 768px){
            flex-direction: column;
            gap: 1px;
            margin-bottom: 0;
        }
     }

  
     
    .logo{
       color: #ffd300;
       text-decoration: none;
        padding-right: 3rem;
        font-weight: 500;
    }
}

.report-button{

    background-color: #010101;
    border-radius: 40px;
    padding: 8px;
    margin: 1rem auto;
    display: flex;
    justify-content: space-around;
    width: 100%;

p{
    cursor: pointer;
    color: whitesmoke;
    padding: 6px;
    border-radius: 50px;
    font-size: .8rem;
   position: relative;
   top: 8px;
   width: fit-content;

}
}


.popup-box{
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100000;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;




.claim{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    background-color: #010101;
    padding: 30px;
    margin: 0 auto;
    z-index: 100000;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
 
   

    .close{
        font-size: 20px;
        background-color: white;
        color: red;
        font-weight: 900;
        cursor: pointer;
     
        position: relative;
        bottom: 42px;
        left: 178px
    }
    h3{
        font-size: 20px;
        color: #fff;
        text-align: center;
        color: #ffd300;
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        label{
            color: #fff;
        }
input{
    outline: none;
    width: 300px;
}
        ::placeholder{
            font-size: 12px;
        }
       
        button{
            background-color: #ffd300;
            cursor: pointer;
            margin-top: 10px;
            width: 300px;

            &:hover{
                background-color: #fff;
                
            }
        }

       
    }

  
}
}


.terms{
   .body{ padding: 2rem;
display: flex;
flex-direction: column;
gap: 2rem;

h1{
    color: #ffd300;
    text-align: center;
    font-size: 2em;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

li{
    margin-bottom: 1em;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: .5px;
}
}
}

.faqs{
    .summary{
        padding: 1rem 2rem;
      
    }
    details{
       
        padding-bottom: 5px;
        background: black;
        border-radius: 10px;
        padding: 15px;
       border-bottom: 5px solid #ffd300;
    
        p{
            color: #fff;
         }
      
        summary{
 margin-bottom: 10px;
 color: #ffd300;


        }
    }
}