@import url("https://fonts.cdnfonts.com/css/Syne");

*{
  box-sizing: border-box;

}

body{
  font-family: 'Syne';

}